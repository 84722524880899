.header-container {
    display: flex;
    align-items: center;
    background-image: linear-gradient(130deg, #0700DD 0%, #00f2ff 100%);
    justify-content: space-between;
    background-color: var(--blue-background);
    padding: 10px 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: white;
  }
  .logo-image {
    width: 120px;
    height: auto;
    margin-right: 10px;
  }
  .header-icons {
    display: flex;
    align-items: center;
  }
  .icon {
    font-size: 18px;
    margin: 0 15px;
    cursor: pointer;
    color: white;
    transition: color 0.3s ease;
    position: relative;
  }
  .icon:hover {
    color: #00f2ff;
  }
  .notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--blue-background);
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
  .user-profile {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    position: relative;
  }
  .user-image {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 5px;
  }
  .user-name {
    font-weight: bold;
    margin-right: 5px;
  }
  .user-dropdown {
    color: #ccc;
    font-size: 14px;
    position: absolute;
    top: 25%;
    left: 5;
    right: 0;
    top: calc(100% + 5px); 
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: none;
    padding: 0;
    margin: 0;
  }
  .user-dropdown-icon i{
    background: none; 
    color: white; 
    border: none; 
    box-shadow: none
  }
  .user-dropdown {
    display: block;
    width: 230px;
  }
  .user-dropdown-item {
    padding: 10px 20px;
    color: #333;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
  }
  .user-dropdown-item:hover {
    background-color: #f8f9fa;
  }
  .dropdown-divider {
    margin: 0;
  }
  .user-dropdown-icon {
    margin-left: 5px;
  }
  .notification-dropdown {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: none;
    padding: 10px 15px 10px 15px;
    margin: 0;
  }

  .icon.active-notification .notification-dropdown {
    display: block;
  }
  .notification-heading {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .notification-description {
    font-size: 12px;
    margin-bottom: 10px;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .notification-item {
    width: 250px;
  }

  .divider {
    margin: 5px 0;
    border-top: 1px solid #ddd;
  }