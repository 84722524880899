@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;0,700;1,400&family=Varela+Round&display=swap');

body {
  font-family: 'Varela Round', sans-serif;
}

/* variables */
:root {
  --ping-orange-gradient: linear-gradient(-160deg, #FF57A2 0%, #FF9A5B 100%);
  --full-orange-gradient: linear-gradient(-160deg, #FF9A5B 0%, #FF9A5B 100%);
  --blue-aqua-gradient: linear-gradient(130deg, #0700DD 0%, #00f2ff 100%);
  --blue-background: #0700DD;
  --pink-color: #FF57A2;
  --orange-color: #FF9A5B;
  --blue-icon: #007bff;
}

@media only screen and (max-width: 768px) {
  .mob-spacing {
    margin-bottom: 22px;
  }
}