.shade-pink{
    color: var(--pink-color);
}
.shade-orange{
    color: var(--orange-color);
}
.blue-aqua-gradient{
    background-color: transparent;
    background-image: linear-gradient(-160deg, #FF57A2 0%, #FF9A5B 100%);
    text-decoration: none;
    border: none;
}
.sheet-icon{
    padding: 6px;
    display: inline-block;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 20px;
}