input {
    background-color: #F3E5F5;
    border-radius: 12px !important;
    padding: 12px 15px 12px 15px !important;
    width: 100%;
    box-sizing: border-box;
    border: none !important;
    border: 1px solid #F3E5F5 !important;
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 400;
}
input[type="file"] {
    background-color: #F3E5F5;
    border-radius: 12px !important;
    padding: 3px 15px 12px 15px !important;
    width: 100%;
    box-sizing: border-box;
    border: none !important;
    border: 1px solid #F3E5F5 !important;
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 400;
}
select{
    background-color: #F3E5F5;
    border-radius: 12px !important;
    padding: 3px 15px 3px 15px !important;
    width: 100%;
    box-sizing: border-box;
    border: none !important;
    border: 1px solid #F3E5F5 !important;
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 400;
}
textarea {
    background-color: #F3E5F5;
    border-radius: 12px !important;
    padding: 12px 15px 12px 15px !important;
    width: 100%;
    box-sizing: border-box;
    border: none !important;
    border: 1px solid #F3E5F5 !important;
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 400;
}

input:focus{
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid var(--blue-background) !important;
    outline-width: 0;
    font-weight: 400;
}

textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid var(--blue-background) !important;
    outline-width: 0;
    font-weight: 400;
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0;
}

.card {
    background-color: transparent;
    border: none;
    border-radius: 12px;
}

.card1 {
    width: 50%;
    padding: 40px 30px 10px 30px;
    border-radius: 12px 0px 0px 12px;
    background-color: transparent;
    border: none;

}

.card2 {
    width: 50%;
    background-color: transparent;
    background-image: linear-gradient(360deg, #0700DD 0%, #00cfda 100%);
    border-radius: 0px 12px 12px 0px;
    border: none;
}

#logo {
    width: 200px;
    height: 60px;
}

.heading {
    margin-bottom: 60px !important;
}

::placeholder {
    color: #000 !important;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #000 !important;
}

::-ms-input-placeholder {
    color: #000 !important;
}

.form-control-label {
    font-size: 12px;
    margin-left: 15px;
}

.msg-info {
    padding-left: 15px;
    margin-bottom: 30px;
}

.btn-color {
    border-radius: 50px;
    color: #fff;
    background-color: transparent;
    background-image: linear-gradient(-160deg, #FF57A2 0%, #FF9A5B 100%);
    padding: 15px;
    cursor: pointer;
    border: none !important;
    margin-top: 20px;
}

.btn-color:hover {
    color: #fff;
    background-color: transparent;
    background-image: linear-gradient(-160deg, #FF9A5B 0%, #FF9A5B 100%);
}

.btn-white {
    border-radius: 50px;
    color: var(--orange-color);
    background-color: #fff;
    padding: 8px 40px;
    cursor: pointer;
    border: 2px solid var(--orange-color) !important;
}

.btn-white:hover {
    color: #fff;
    background-color: transparent;
    background-image: linear-gradient(-160deg, #FF9A5B 0%, #FF9A5B 100%);
    border: 2px solid var(--orange-color);
}

a {
    color: #000;
}

a:hover {
    color: #000;
}

.bottom {
    width: 100%;
    margin-top: 8px;
}

.sm-text {
    font-size: 15px;
}

/* Sign in button */
.login-with-google-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88%;
    padding: 15px;
    border: none;
    border-radius: 50px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-color: white;
    transition: background-color .3s, box-shadow .3s;

    &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    }

    &:active {
        background-color: #eeeeee;
    }

    &:focus {
        outline: none;
        box-shadow:
            0 -1px 0 rgba(0, 0, 0, .04),
            0 2px 4px rgba(0, 0, 0, .25),
            0 0 0 3px #c8dafc;
    }

    &:disabled {
        filter: grayscale(100%);
        background-color: #ebebeb;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
        cursor: not-allowed;
    }
}
.login-with-google-btn .button-content {
    display: flex;
    align-items: center;
}
.login-with-google-btn .google-logo {
    display: inline-block;
    width: 24px; /* Adjust the size of the logo as needed */
    height: 24px; /* Adjust the size of the logo as needed */
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 8px; /* Adjust spacing between logo and text */
}

.card-shadow{
    box-shadow: 0 14px 28px rgba(73, 73, 73, 0.25), 0 10px 10px rgba(73, 73, 73, 0.25);
    border-radius: 10px;
}

.error {
    color: red;
    font-size: 0.8em;
    margin-top: 0.25em;
}

.privacy-terms-links {
    margin-top: 10px;
    font-size: 14px;
 }
 
 .privacy-terms-links a {
    color: #FFFFFF; /* or any other color that stands out on blue */
    text-decoration: underline; /* makes it obvious that it's a link */
 }
 
 .privacy-terms-links a:hover {
    text-decoration: none; /* style change on hover (optional) */
    color: #B0B0B0; /* change if needed */
 } 

@media screen and (max-width: 992px) {
    .card1 {
        width: 100%;
        padding: 40px 30px 10px 30px;
    }

    .card2 {
        width: 100%;
    }

    .right {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 10px !important;
    }

    .card2 {
        padding: 50px;
    }

    .right {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }
}