.label-with-icon {
    display: flex;
    align-items: center; /* Vertically align the items in the middle */
}

.magic-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out; /* Smooth transition for transform property */
}

.magic-icon:hover {
    transform: scale(1.3); /* Scale up icon size by 10% on hover */
}