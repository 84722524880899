.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.popup-title {
  margin-top: 0;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: white; /* Changed to white */
}

.popup-buttons {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin-top: 20px;
}

.popup-button {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(-160deg, #FF57A2 0%, #FF9A5B 100%);
  text-decoration: none;
  border: none;
  border-radius: 50px;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.popup-button:hover {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(-160deg, #FF9A5B 0%, #FF9A5B 100%);
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: white;
}

.workspace-logo-card {
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.workspace-logo-image {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 10px;
}
