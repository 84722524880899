.workspace-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.workspace-title {
  font-size: 24px;
  font-weight: bold;
}

.workspace-icons {
  display: flex;
  align-items: center;
}

.workspace-icons i {
  color: var(--blue-icon);
}

.plus-icon {
  font-size: 22px;
  color: var(--blue-icon);
  cursor: pointer;
  margin-right: 10px;
}

.card-line {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.campaign-card {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;
}

.campaign-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.settings-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.settings-icon i {
  font-size: 22px;
  color: #FF57A2;
}

.card-icon {
  font-size: 50px;
  color: var(--blue-icon);
  margin-bottom: 10px;
}

.card-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  align-self: center;
  margin-bottom: 10px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-button {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(-160deg, #FF57A2 0%, #FF9A5B 100%);
  text-decoration: none;
  border: none;
  border-radius: 50px;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.card-button:hover {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(-160deg, #FF9A5B 0%, #FF9A5B 100%);
}