.workspace-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.workspace-title {
  font-size: 24px;
  font-weight: bold;
}

.workspace-icons {
  display: flex;
  align-items: center;
}

.close-popup {
  color: var(--blue-icon);
  padding: 8px;
  transition: background-color 0.3s;
  z-index: 99;
}

.icon-style {
  display: inline-block;
  border-radius: "15%";
  padding: "8px";
  color: "white";
}

.fa-pencil {
  background-color: "#ff66ab";
}

.fa-trash-o {
  background-color: "#FF9A5B";
}

.workspace-icons i {
  color: var(--blue-icon);
}

.plus-icon {
  font-size: 22px;
  color: var(--blue-icon);
  cursor: pointer;
  margin-right: 10px;
}

.card-line {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.campaign-card {
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;
}

.campaign-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.card-icon {
  font-size: 50px;
  color: var(--blue-icon);
  margin-bottom: 10px;
}

.card-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  align-self: center;
  margin-bottom: 10px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.list-group-item-button {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(-160deg, #ff57a2 0%, #ff9a5b 100%);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.list-group-item-button:hover {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(-160deg, #ff9a5b 0%, #ff9a5b 100%);
}

.list-group-item {
  box-shadow: 0 10px 20px rgba(99, 99, 99, 0.1);
  margin-bottom: 15px;
  /* Adjust this value to control the gap */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}

.list-group-item h4 {
  padding-top: 0.3rem !important;
}

.list-item-content {
  display: flex;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.active {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(-160deg, #ff57a2 0%, #ff9a5b 100%);
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.inactive {
  border-radius: 10px;
  color: var(--orange-color);
  background-color: #fff;
  padding: 3px 9px;
  border: 2px solid var(--orange-color) !important;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
